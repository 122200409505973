@charset "UTF-8";

/* --- Webfonts --- */
@font-face {
	font-family: "VW Head";
	src: url("../fonts/vw/VWHeadWeb-Light.eot");
	src: url("../fonts/vw/VWHeadWeb-Light.eot?#iefix") format("embedded-opentype"),
		url("../fonts/vw/VWHeadWeb-Light.woff2") format("woff2"),
		url("../fonts/vw/VWHeadWeb-Light.woff") format("woff"),
		url("../fonts/vw/VWHeadWeb-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "VW Head";
	src: url("../fonts/vw/VWHeadWeb-Regular.eot");
	src: url("../fonts/vw/VWHeadWeb-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/vw/VWHeadWeb-Regular.woff") format("woff"),
		url("../fonts/vw/VWHeadWeb-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "VW Head";
	src: url("../fonts/vw/VWHeadWeb-Bold.eot");
	src: url("../fonts/vw/VWHeadWeb-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/vw/VWHeadWeb-Bold.woff") format("woff"),
		url("../fonts/vw/VWHeadWeb-Bold.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: "VW Text";
	src: url("../fonts/vw/VWTextWeb-Light.eot");
	src: url("../fonts/vw/VWTextWeb-Light.eot?#iefix") format("embedded-opentype"),
		url("../fonts/vw/VWTextWeb-Light.woff2") format("woff2"),
		url("../fonts/vw/VWTextWeb-Light.woff") format("woff"),
		url("../fonts/vw/VWTextWeb-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: "VW Text";
	src: url("../fonts/vw/VWTextWeb-Regular.eot");
	src: url("../fonts/vw/VWTextWeb-Regular.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/vw/VWTextWeb-Regular.woff") format("woff"),
		url("../fonts/vw/VWTextWeb-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "VW Text";
	src: url("../fonts/vw/VWTextWeb-Bold.eot");
	src: url("../fonts/vw/VWTextWeb-Bold.eot?#iefix") format("embedded-opentype"),
		url("../fonts/vw/VWTextWeb-Bold.woff") format("woff"),
		url("../fonts/vw/VWTextWeb-Bold.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}
